//@import '~font-awesome/css/font-awesome.css';

@font-face {
  font-family: "Roboto";
  src: url(../fonts/Roboto-Black.ttf);
  font-weight: 900;
}

@font-face {
  font-family: "Roboto";
  src: url(../fonts/Roboto-Bold.ttf);
  font-weight: 700;
}

@font-face {
  font-family: "Roboto";
  src: url(../fonts/Roboto-Medium.ttf);
  font-weight: 500;
}

@font-face {
  font-family: "Roboto";
  src: url(../fonts/Roboto-Regular.ttf);
  font-weight: 400;
}

@font-face {
  font-family: "Roboto";
  src: url(../fonts/Roboto-Light.ttf);
  font-weight: 300;
}

@font-face {
  font-family: "Roboto";
  src: url(../fonts/Roboto-Thin.ttf);
  font-weight: 100;
}

