$grey: #747971;
$grey-light: #969696;
$black: #525252;
$black-dark: #505050;
$black-light: #686C65;
$violet: #C12878;
$pink: #D40F5F;
$red: #850C37FF;
$pink-light: #F0156E;
$white: #fff;
$green: #69B227;
$bg-main: #F9FAFC;
$bg-sub: #FEFEFE;
$bg-img: #FAFAFA;