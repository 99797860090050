@import "vars";
@import "fonts";
@import "~bulma/bulma";
@import "footer";
@import "modal";
@import "pie-graph";
@import "custom-button";
@import "custom-input";
@import "bulma-custom";
@import "main";
@import "responsive";
