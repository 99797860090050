.contact-form {

  &__inputs {
    width: 100%;
  }

  &__textarea {
    width: 100%;
    height: 100%;
  }

  &__inputs {
    margin-right: 46px;

    .custom-input:not(:last-child) {
      margin-bottom: 22px;
    }
  }
}

.custom-input {
  display: flex;
  flex-direction: column-reverse;
  width: 100%;

  &__textarea {
    height: 100%;
  }

  .warning {
    display: none;
    padding-top: 7px;
    font-size: 14px;
    font-weight: normal;
    color: $red;

    &.message {
      background: transparent;
      margin-bottom: 0;
    }
  }

  label {
    color: $black-dark;
    font-weight: 400;
    font-size: 16px;
    margin-bottom: 15px;
  }

  input {
    color: $black-dark;
    padding: 1.5rem;
    height: 52px;
    border-radius: 10px;
    background: $white;
    border: none;
    outline:none;
  }

  textarea {
    height: 100%;
    color: $black-dark;
    padding: 1.5rem;
    resize: none;
    background: $white;
    border: none;
    border-radius: 10px;
    outline:none;
  }

  input:focus,
  textarea:focus {
    border: 1px solid $pink;
  }

  input:focus ~ label,
  textarea:focus ~ label {
    color: $pink;
  }
}



