.custom-button {
  padding: 22px 40px;
  border: none;
  border-radius: 10px;
  background: $violet;
  font-family: 'Inter', sans-serif;
  font-weight: bold;
  font-size: 18px;
  color: $white;
  letter-spacing: 0.03em;
  cursor: pointer;
  transition: all .3s ease-in-out;
  backface-visibility: hidden;
  -webkit-font-smoothing: antialiased;

  &:hover {
    transform: scale(1.02);
  }

  &:active {
    box-shadow: unset;
    transform: scale(1.01);
  }

  &:disabled {
    background: $grey;

    &:hover {
      transform: none;
    }
  }


  &--green {
    background: $green;
  }
}
.upper {
  position: fixed; /* Фиксированное положение */
  right: 20px; /* Расстояние от правого края окна браузера */
  bottom: 10%; /* Расстояние сверху */
  transition: all .5s ease-in-out;
  opacity: 0;
  z-index: 9999;
}

.upper._anim-active {
  opacity: 1;
}