.navbar {
  padding-top: 30px;

  .navbar-menu {

    .navbar-start {
      margin-left: auto;
      margin-right: unset;

      .navbar-item {
        padding-left: 1.4rem;
        padding-right: 1.4rem;

        a {
          color: $grey;
          transition: color ease-in-out .3s;

          &:hover {
            color: $black;
          }
        }
      }
    }

    .navbar-end {

      .navbar-item {

        .phone {
          font-size: 24px;
          color: $black;
          text-decoration-line: none;
          transition: color ease-in-out .3s;

          &:before {
            display: none;
          }

          &:hover {
            color: $pink;
          }

        }

        a {
          position: relative;
          align-self: flex-end;
          font-size: 14px;
          color: $pink;

          &:last-child {

            &:after {
              content: '';
              width: 100%;
              height: 1px;
              display: block;
              background: $pink;
              transition: 300ms;
            }

            &:hover:after {
              width: 0;
            }
          }

          &:before {
            content: '';
            display: block;
            position: absolute;
            width: 24px;
            height: 14px;
            left: -24px;
            top: 4px;
            background-image: url("../img/phone.svg");
            background-repeat: no-repeat;
          }
        }
      }
    }
  }

  &__phone {
    display: flex;
    text-align: right;
  }

  .logo {
    max-height: unset;
    height: 80px;
    width: 80px;
  }
}