html {
  scroll-behavior: smooth;
}

body {
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 18px;
  color: $black;

  a, button {
    transition: color ease-in-out .3s;
  }
}

::-webkit-scrollbar {
  display: none;
}

.main {
  position: relative;
  background: url("../img/main-hero.png") no-repeat bottom center;
  height: calc(100vh - 116px);
  min-height: 750px;
  max-height: 1162px;

  &__list {
    font-family: 'Inter', sans-serif;
    position: relative;
    z-index: -1;
    width: 450px;
    margin-top: 90px;
    margin-right: auto;
    margin-left: 22px;
    background: $white;
    padding: 30px 36px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
    border-radius: 20px;
    color: $black-dark;

    & > *:not(:last-child) {
      margin-bottom: 20px;
    }

    h3 {
      font-size: 24px;
      font-weight: 500;
      line-height: 31px;
    }

    ul {
      font-weight: 400;

      li {
        padding-left: 40px;
        position: relative;
        margin-bottom: 15px;

        &:last-child {
          margin-bottom: 0;
        }

        &:before {
          content: '';

          position: absolute;
          display: block;
          width: 23px;
          height: 16px;
          left: 0;
          top: 4px;
          background-image: url("../img/check.svg");
        }
      }
    }
  }

  &__address {
    position: absolute;
    display: flex;
    bottom: 125px;
    right: 21vw;

    &-item {
      display: flex;
      width: 270px;
      padding: 18px 7px 18px 18px;
      border-radius: 20px;
      background: $white;
      box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
      font-weight: 400;
      line-height: 23px;
      color: $black-dark;

      img {
        align-self: center;
        margin-right: 26px;
      }

      &:last-child {
        width: 300px;
      }

      &:not(:last-child) {
        margin-right: 20px;
      }
    }
  }

  .links-menu {
    position: absolute;
    right: 10px;
    top: 40%;
    transform: translateY(-60%);
    width: 60px;

    a {
      display: block;
      height: 50px;
      margin-bottom: 12px;

      circle {
        transition: fill ease-in-out .3s;
      }

      &:hover {

        circle {

          fill: $pink-light;
        }
      }
    }
  }

  .cloud-block {
    margin-top: 110px;
    margin-bottom: 80px;

    & > *:not(:last-child) {
      margin-bottom: 10px;
    }

    h1 {
      font-family: "Inter", sans-serif;
      color: $violet;
      font-weight: 700;
      font-size: 72px;
      line-height: 84px;
    }

    h2 {
      font-family: "Inter", sans-serif;
      font-weight: 400;
      font-size: 36px;
      line-height: 42px;
    }

    p {
      font-weight: 400;
      font-size: 18px;
      color: $grey;
    }
  }
}

.information {
  font-family: 'Inter', sans-serif;
  padding-top: 90px;
  padding-bottom: 50px;
  text-align: center;

  h1 {
    font-weight: 700;
    font-size: 48px;
    line-height: 56px;
    color: $black-dark;
  }

  .container__info {
    width: 70%;
    margin: 10px auto 60px auto;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: $black-dark;
  }

  &__graph {
    //width: 480px;
    //height: 530px;
    //background: url("/src/static/test-graph.png") no-repeat center;
  }

  &__blocks {
    display: grid;
    grid-template-columns: 1fr 320px;
    grid-template-areas: "first-block second-block"
                         "third-block third-block";
    gap: 14px;
    margin-top: 30px;
  }

  &__block {
    background: $white;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.08);
    border-radius: 20px;
    align-self: start;
    justify-self: end;


    p {
      font-weight: 400;
    }

    h3 {
      font-weight: 400;
      font-size: 18px;
      line-height: 31px;
      color: $black-dark;
      margin-bottom: 20px;
    }

    h2 {
      margin-bottom: 20px;
    }

    &:nth-child(1) {
      z-index: 3;
      grid-area: first-block;
      max-width: 450px;
      padding: 74px 90px 77px 90px;
    }

    &:nth-child(2) {
      z-index: 5;
      grid-area: second-block;
      max-width: 320px;
      padding: 58px 78px 58px 78px;
    }

    &:nth-child(3) {
      z-index: 4;
      display: flex;
      align-items: center;
      grid-area: third-block;
      justify-self: center;
      margin-left: 115px;
      max-width: 460px;
      padding: 46px 50px 46px 50px;
      text-align: left;
      box-shadow: 0 4px 10px rgba(0, 0, 0, 0.02), 0 4px 10px rgba(0, 0, 0, 0.10);
      margin-top: -65px;

      h2 {
        margin-bottom: 0;
        margin-right: 35px;
      }
    }


    h2 {
      font-size: 48px;
      font-weight: 700;
      line-height: 58px;
      color: $green;

      sup {
        font-size: 36px;
      }
    }

    p {
      font-size: 18px;
      color: $black-dark;
    }
  }
}

.quick-test {
  padding-top: 90px;
  padding-bottom: 110px;

  h1 {
    font-weight: 700;
    font-size: 48px;
    line-height: 56px;
    text-align: center;
    color: $black-dark;
  }

  .container__info {
    font-weight: 400;
    font-size: 18px;
    text-align: center;
    margin-top: 16px;
    margin-bottom: 50px;
    color: $grey;

    &--bg {


      span {
        position: absolute;
        color: $green;

        &:first-child {
          font-size: 300px;
          right: -88px;
          top: 120px;
          opacity: .2;
          transform: rotate(-15deg);
        }

        &:last-child {
          font-size: 200px;
          right: 55px;
          top: 110px;
          opacity: .1;
          transform: rotate(-45deg);
        }
      }
    }

    &-sub {
      max-width: 400px;
      font-weight: 400;
      font-size: 18px;
      line-height: 23px;
      color: $black-dark;

      margin-bottom: 10px;

      &:nth-child(2) {
        margin-bottom: 40px;
      }
    }
  }

  ul {
    max-width: 488px;
    padding: 0 40px 40px 76px;

    li {
      position: relative;
      font-weight: 400;

      &:not(:last-child) {
        margin-bottom: 20px;
      }

      &:before {
        content: '';
        position: absolute;
        display: block;
        width: 20px;
        height: 20px;
        border: 5px solid $violet;
        border-radius: 50%;
        left: -36px;
        top: 3px;
      }
    }
  }

}

.objects {
  padding-top: 90px;
  padding-bottom: 100px;

  h1 {
    font-weight: 700;
    font-size: 48px;
    line-height: 56px;
    text-align: center;
    color: $black-dark;
  }

  p {
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: $black-dark;
    margin-bottom: 60px;
  }

  &__firms {
    margin-bottom: 50px !important;

    div {
      text-align: center;
    }

    img {
      height: 180px;
      width: 100%;
      padding: 60px 50px;
      background: $white;
      box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
      border-radius: 20px;
      object-fit: contain;
      transition: all .3s ease-in-out;

      &:hover {
        transform: scale(1.02);
      }
    }
  }

  .custom-button {
    display: block;
    margin: 50px auto 0;
  }

  p.objects__info {
    max-width: 380px;
    margin-bottom: 0;
    padding: 0 40px 20px 40px;
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    text-align: left;
  }

  p.objects__price {
    padding: 0 40px;
    margin: 0;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    text-transform: uppercase;
    color: $green;
    text-align: left;
    padding-bottom: 40px;
  }

  &__item {
    background: $white;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
    border-radius: 20px;
    overflow: hidden;
    cursor: pointer;
    transition: transform ease-in-out .3s;

    img {
      height: 290px;
      width: 100%;
      object-fit: cover;
    }

    &:hover {
      transform: scale(1.02);
    }

    h3 {
      max-width: 355px;
      padding: 30px 40px 20px 40px;
      font-weight: 500;
      font-size: 24px;
      line-height: 31px;
      text-align: left;
    }
  }
}

.reviews {
  padding-top: 90px;
  padding-bottom: 70px;

  .columns {
    position: relative;
    overflow-x: scroll;
    scroll-snap-type: x mandatory;
  }

  .slide {
    scroll-snap-align: end;
    overflow-y: scroll;
    max-height: 580px;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 75px;

    h1 {
      font-weight: 700;
      font-size: 48px;
      line-height: 56px;
      text-align: left;
      color: $black-dark;
    }

    a {
      font-weight: 700;
      position: relative;
      line-height: 21px;
      text-align: right;
      letter-spacing: 0.05em;
      text-transform: uppercase;
      color: $green;

      &:after {
        content: '';
        width: 0;
        height: 1px;
        display: block;
        background: $green;
        transition: 300ms;
      }

      &:hover:after {
        width: 100%;
      }

      &:before {
        content: '';
        position: absolute;
        display: block;
        width: 84px;
        height: 42px;
        top: -12px;
        left: -60px;
        background: url("../img/plus-circle.svg") no-repeat;
      }
    }
  }

  &__arrow {
    position: absolute;
    width: 31px;
    height: 31px;
    cursor: pointer;
    transition: border-color ease-in-out .1s;

    &:hover {
      border-color: #E2E2E2;
    }

    &--left {
      transform: rotate(-45deg) translateY(-50%);
      top: 50%;
      left: -34px;
      border-left: 5px solid $violet;
      border-top: 5px solid $violet;
    }

    &--right {
      transform: rotate(-45deg) translateY(-50%);
      top: 50%;
      border-color: $violet;
      left: unset;
      right: -55px;
      border-right: 5px solid $violet;
      border-bottom: 5px solid $violet;
    }

  }

  &__user {
    display: flex;
    width: 350px;
    margin-left: auto;
    margin-right: auto;

    img {
      width: 75px;
      height: 75px;
      border-radius: 50%;
      margin-right: 25px;
      margin-bottom: 20px;
      background: $bg-img;
      object-fit: none;
    }

    h3 {
      margin-bottom: 6px;
      font-weight: 700;
      font-size: 24px;
      line-height: 31px;
      text-align: left;
      color: $black;
    }
  }

  &__comment {
    max-width: 350px;
    font-weight: 400;
    font-size: 16px;
    line-height: 27px;
    color: $black-dark;
    margin-left: auto;
    margin-right: auto;
  }

  .custom-button {
    display: block;
    margin-top: 75px;
    margin-left: auto;
    margin-right: auto;
  }
}

.agents {
  padding-top: 90px;
  padding-bottom: 70px;
  text-align: center;

  ::-webkit-scrollbar {
    display: block;
    width: 9px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    border: 4px solid transparent;
    background: #F3F3F3;
    border-radius: 100px;
    background-clip: content-box;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    width: 20px;
    border-radius: 10px;
    background: $green;
    border: 6px solid #ccf1dd;
  }

  ::-webkit-scrollbar-button:end:increment {
    width: 40%;
    display: block;
    background: transparent;
  }

  ::-webkit-scrollbar-button:start:decrement {
    width: 40%;
    display: block;
    background: transparent;
  }

  ::-webkit-scrollbar-track-piece:end {
    margin-right: 20px;
  }

  ::-webkit-scrollbar-track-piece:start {
    margin-left: 20px;
  }

  h1 {
    font-weight: 700;
    font-size: 48px;
    line-height: 58px;
    text-align: center;
    color: $black-dark;
  }

  p {
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: $black-dark;
    margin-top: 16px;
  }

  h3 {
    padding: 0 23px;
    font-weight: 500;
    font-size: 24px;
    line-height: 31px;
    color: $black;
    margin-bottom: 12px;
  }

  p.agents__position {
    margin: 0;
    margin-bottom: 12px;
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
    color: $grey-light;
  }

  &__number {
    display: block;
    position: relative;
    margin-bottom: 26px;
    padding-left: 20px;
    font-weight: 500;
    font-size: 24px;
    line-height: 31px;
    color: #D40F5F;

    &:after {
      content: '';
      width: 0;
      margin: auto;
      height: 1px;
      display: block;
      background: $pink;
      transition: 300ms;
    }

    &:hover {
      color: #D40F5F;

      &:after {
        width: 60%;
      }
    }

    &:before {
      content: '';
      position: absolute;
      display: block;
      width: 28px;
      height: 18px;
      background: url("../img/phone-alt.svg") no-repeat;
      top: 8px;
      left: 50px;
      margin-bottom: 26px;
    }
  }

  &__link {
    display: block;
    margin-bottom: 90px;

    &:hover {

      path {

        fill: $pink-light;
        transition: fill ease-in-out .3s;
      }
    }
  }

  .columns {
    overflow-x: scroll;
    scroll-snap-type: x mandatory;

    .column {
      scroll-snap-align: start;
    }
  }
}

.contacts {
  padding-top: 90px;
  padding-bottom: 100px;


  &__information {

    h1 {
      font-weight: 700;
      font-size: 48px;
      line-height: 56px;
      text-align: left;
      margin-bottom: 16px;
      color: $black-dark;
    }

    p {
      font-weight: 400;
      font-size: 18px;
      line-height: 31px;
      text-align: left;
      color: $black-dark;
      margin-bottom: 27px;

      &.name,
      &.place {
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        margin-bottom: 0;
      }

      &.place {
        color: $violet;
      }
    }

    a {
      font-weight: 500;
      font-size: 24px;
      line-height: 31px;
      color: $black-dark;

      &:hover {
        color: $grey;
      }
    }

    ul {

      li {
        height: 48px;
        padding-left: 65px;
        margin-bottom: 34px;
        background: url("../img/circle-phone.svg") no-repeat;
        left: 0;
        top: 0;

        &:nth-child(1),
        &:nth-child(2) {
          margin-bottom: 50px;
        }
        
        &:nth-child(3),
        &:nth-child(4) {
          p {
            font-weight: 500;
            font-size: 24px;
            line-height: 31px;
            text-align: left;
            color: $black;
            margin: 0;
          }
          height: 42px;
          background: url("../img/main-map.svg") no-repeat;
          background-size: contain;

        }

        &:nth-child(3) {
          padding-top: 7px;
        }

        &:nth-child(4) {
          width: 85%;
        }
      }
    }
  }

  &__map {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    border-radius: 20px;
    overflow: hidden;

    div, iframe {
      width: 100%;
      height: 100%;
    }
  }

  &__description {
    margin-top: 80px;
    margin-bottom: 40px;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    color: $black-dark;
  }

  .custom-button {
    display: block;
    margin: 50px auto 0 auto;
    padding-left: 50px;
    padding-right: 50px;
  }
}

.slider {
  display: flex;
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  padding: 55px 0 45px;

  .img-wrapper {
    position: absolute;
    bottom: 0;
    left: 16px;
  }

  &__item {
    position: relative;
    display: flex;
    flex-shrink: 0;
    max-width: 488px;
    width: 488px;
    max-height: 320px;
    min-height: 320px;
    padding: 45px;
    background: $green;
    border-radius: 20px;
    scroll-snap-align: end;

    &:not(:last-child) {
      margin-right: 30px;
    }

    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(8) {
      img {
        max-height: 349px;
      }
    }

    &:nth-child(3) {
      img {
        max-height: 349px;
      }
    }
    &:nth-child(4) {
      img {
        max-height: 341px;
      }
    }
    &:nth-child(5) {
      img {
        max-height: 349px;
      }
    }

    img {
      max-height: 365px;
      width: 100%;
      object-fit: contain;
    }

  }

  .agent {
    position: relative;
    display: flex;
    flex-direction: column;
    text-align: left;
    width: 42%;
    margin-left: auto;
    justify-content: space-between;

    &__name {
      font-weight: 500;
      font-size: 24px;
      line-height: 31px;
      color: $white;
      text-align: left;
    }

    &__profession {
      font-weight: 400;
      font-size: 16px;
      line-height: 21px;
      color: $white;
      text-align: left;
    }

    &__links {
      display: flex;
      flex-direction: column;
      margin-top: auto;

      a {
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 28px;
        padding-left: 25px;
        color: $white;

        span {
          position: relative;

          &:after {
            position: absolute;
            content: '';
            width: 0;
            height: 1px;
            display: block;
            right: 0;
            background: $white;
            transition: 300ms;
          }
        }

        &:hover {

          span {
            &:after {
              width: 100%;
            }
          }
        }

        &:before {
          position: absolute;
          content: '';
          background-size: contain;
          display: block;
          top: 5px;
          left: 0;
          width: 16px;
          height: 16px;
        }
      }

      &--phone {
        position: relative;

        &:before {
          background: url("../img/phone-alt.svg") no-repeat center;
        }
      }

      &--social {
        position: relative;

        &:before {
          top: unset;
          background: url("../img/inst-alt.svg") no-repeat center;
        }
      }
    }
  }
}

.navbar-mobile-phone {
  display: none;
}

.bg-color-1 {
  background: $bg-main;
}

.bg-color-2 {
  background: $bg-sub;
}
