.pie-wrapper {
  position: relative;
  margin-left: 115px;

  &__text {
    position: absolute;
    top: 230px;
    left: 80px;

    text-align: left;
    font-weight: 500;
    font-size: 18px;
    color: $white;
  }
}