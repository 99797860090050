.modal {
  display: none;
  visibility: visible;
  position: fixed;
  z-index: 9999;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0,0,0);
  background-color: rgba(0,0,0,0.4);
  -webkit-animation-name: fadeIn;
  -webkit-animation-duration: 0.4s;
  animation-name: fadeIn;
  animation-duration: 0.4s;

}

.modal-content {
  position: relative;
  background: #FFFFFF;
  box-shadow: 0 29px 40px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
  margin: auto;
  width: 80%;
  max-width: 927px;
  //height: 90%;
  overflow: scroll;

  &__load {
    width: 100%;
    height: 1px;
    background: #EFEFEF;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      display: block;
      top: -2.5px;
      width: 5%;
      height: 5px;
      background: $green;
    }
  }

  &.one {
    display: block;
  }

  &.two {
    display: none;

    .modal-content__load {
      &:before {
        width: 36%;
      }
    }
  }

  &.three {
    display: none;

    .modal-content__load {
      &:before {
        width: 88%;
      }
    }
  }

  &.success {
    display: none;

    .modal-content__load {
      &:before {
        width: 100%;
      }
    }

    h2 {
      font-weight: 500;
      font-size: 24px;
      line-height: 31px;
      color: $black-dark;
    }

    .custom-input:first-child {
        margin-bottom: 30px;
    }

    input {
      background: $bg-main;
      max-width: 505px;
    }

    .modal-content__buttons {
      margin-top: 70px;
    }
  }

  ul {
    margin-bottom: 72px;
  }

  &__buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px 60px;

    h2 {
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 23px;
      color: $grey;
    }

    h3 {
      font-weight: 700;
      font-size: 24px;
      line-height: 31px;
      color: $pink;
    }
  }

  &__test {
    padding: 40px 60px 50px;

    h1 {
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 31px;
      color: $black;
      margin-bottom: 30px;
    }

  }

  .checkmark {
    position: absolute;
    top: 3px;
    left: 0;
    height: 26px;
    width: 26px;
    background-color: transparent;
    border: 2px solid $green;
    border-radius: 50%;

    &:after {
      content: "";
      position: absolute;
      display: none;
    }
  }

  &__label {
    display: block;
    position: relative;
    height: 28px;
    padding-top: 6px;
    padding-left: 40px;
    margin-bottom: 16px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    font-weight: 400;
    font-size: 18px;
    line-height: 23px;
    color: $grey;

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
    }

    &:hover input ~ .checkmark {
      background-color: #d7e1cd;
    }

    input:checked ~ .checkmark:after {
      display: block;
      top: 3px;
      left: 3px;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      background: $green;
    }
  }
}

@-webkit-keyframes slideIn {
  from {bottom: -300px; opacity: 0}
  to {bottom: 0; opacity: 1}
}

@keyframes slideIn {
  from {bottom: -300px; opacity: 0}
  to {bottom: 0; opacity: 1}
}

@-webkit-keyframes fadeIn {
  from {opacity: 0}
  to {opacity: 1}
}

@keyframes fadeIn {
  from {opacity: 0}
  to {opacity: 1}
}