@media (max-height: 900px) {

  .main__list {
    z-index: 1;
  }
}

@media (min-width: 1921px) {
  .main__address {
    right: 40vw;
  }
}

@media (min-width: 1024px) and (max-width: 1810px) {

  .main__address {
    right: 16.2vw;
  }
}

@media (min-width: 1080px) and (max-width: 1213px) {
  .agents p.agents__position {
    height: 46px;
  }

  .agents__number {
    font-size: 18px;

    &:before {
      display: none;
    }
  }

  .information {
    .columns.is-multiline {
      flex-wrap: nowrap;
    }

    .column.is-offset-2, .column.is-offset-2-tablet {
      margin-left: 5%;
    }
  }
}

@media (max-width: 1408px) {
  .pie-wrapper {
    margin-left: 10px;
  }
}

@media (max-width: 1278px) {
  .reviews__arrow--left {
    left: -12px;
  }
  .reviews__arrow--right {
    right: -30px;
  }
}

@media (max-width: 1215px) {
  .slider__item {
    max-width: 425px;
    padding: 45px 10px;

    &:nth-child(2) {
      .img-wrapper {
        left: -26px;
      }
    }

    &:nth-child(3) {
      .img-wrapper {
        left: -34px;
      }

      img {
        max-height: 351px;
      }
    }
  }

  .slider {

    .agent {
      width: 50%;
    }
  }
}

@media (max-width: 1132px) {

  .navbar {

    &__phone {
      display: none;
    }
  }

  .information {
    overflow-x: hidden;
  }
}

@media (max-width: 1085px) {
  .columns {
    margin-left: 0;
    margin-right: 0;
  }

  .main {
    background-position: left;

    .column.is-0-mobile {
      display: none;
    }

    .column.is-6-widescreen.is-12-tablet.is-12-mobile.is-6-desktop {
      width: 100%;
    }

    &__list {
      display: none;
    }

    &__address {
      right: 0;
      bottom: 248px;
    }
  }

  .pie-wrapper {
    .pie, .pie-shadow {
      width: 470px !important;
      height: 470px !important;
      margin: auto;
      left: 0;
      right: 0;
    }

    left: -93px;
  }

  .information {

    .columns {
      justify-content: center;

      .is-multiline {
        flex-wrap: wrap;
      }
    }

    &__blocks {
      grid-template-columns: 1fr 1fr;
      margin-top: -155px;
    }

    &__block {
      justify-self: center;

      h2 {
        font-size: 36px;

        sup {
          font-size: 24px;
        }


      }

      p {
        font-size: 18px;
      }

      &:nth-child(2) {
        justify-self: start;
      }

      &:nth-child(3) {
        margin-left: 0;
      }
    }

    .column.is-8-desktop.is-8-widescreen.is-8-tablet.is-offset-2-tablet.is-offset-0-widescreen {

      margin-left: 2.66667%;
    }

  }

  .reviews {

    &__user {
      width: 100%;
    }
  }

  .quick-test .container__info--bg span:first-child {
    right: 125px;
  }

}

@media (max-width: 1023px) {
  .navbar-menu {
    width: 100%;
    position: absolute;
  }
}

@media (max-width: 1023px) {


  .pie-wrapper {
    left: -95px;

    &__text {
      max-width: 220px;
      left: 292px;
    }
  }

  .main {

    &__address {
      bottom: 248px;
    }
  }
}

@media (max-width: 980px) {

  .pie-wrapper {

    &__text {
      max-width: 220px;
      left: 160px;
    }
  }

  .main__address {
    display: none;
  }

  .objects__item h3 {
    font-size: 18px;
  }

  .objects p.objects__info {
    font-size: 14px;
  }

  .objects p.objects__price {
    font-size: 24px;
  }

  .reviews__user h3 {
    font-size: 20px;
  }

  .reviews__comment {
    font-size: 12px;
  }
}

@media (max-width: 768px) {

  .links-menu {
    display: none;
  }

  .navbar-brand {
    justify-content: space-around;
    align-items: center;

    .navbar-item {

      svg {
        height: 45px !important;
        width: 155px !important;
      }
    }

    .navbar-mobile-phone {

      svg {
        height: 26px !important;
        width: 26px !important;
      }
    }
  }

  .navbar-burger {
    margin: 0;

    &:hover {
      background-color: inherit;
    }

    span {
      width: 36px;
      height: 6px;
      background: $grey;
      border-radius: 12px;
      left: 0;

      &:nth-child(2) {
        top: calc(50% - 15px);
      }
    }
  }

  .navbar-brand, .navbar-tabs {
    flex-direction: row-reverse;
  }

  .navbar-mobile-phone {
    display: block;
  }

  .main {
    background: url("../img/main-hero.png") no-repeat bottom center;

    &__address {
      display: flex;
      bottom: 125px;
      right: 83px;
    }

    .cloud-block {
      margin-top: 30px;
      margin-bottom: 70px;
    }

    .custom-button {
      padding: 22px 28px;
      font-size: 14px;
    }

    h1 {
      font-size: 64px;
      line-height: 40px;
    }
  }

  .information {
    padding-bottom: 70px;
    padding-top: 70px;

    h1 {
      font-size: 36px;
      line-height: 40px;
    }

    &__blocks {
      grid-template-columns: 1fr 150px;
      margin-top: -100px;
      gap: 5px;
    }

    &__block {
      justify-self: center;

      h3 {
        margin-bottom: 4px;
        font-size: 12px;
      }

      h2 {
        font-size: 30px;
        line-height: 35px;
        margin-bottom: 12px;

        sup {
          font-size: 16px;
        }


      }

      p {
        font-size: 12px;
      }

      &:nth-child(1) {
        padding: 26px 18px 26px 18px;
        justify-self: start;
      }

      &:nth-child(2) {
        padding: 18px 22px 20px 22px;
        justify-self: start;
      }

      &:nth-child(3) {
        width: 250px;
        padding: 18px 16px 18px 22px;
        margin-left: 0;
        margin-top: -16px;
      }
    }

    .columns {
      overflow: hidden;
    }

    .container__info {
      font-size: 20px;
      width: 100%;
    }
  }

  .pie-wrapper {
    left: 0;

    .pie, .pie-shadow {
      width: 340px !important;
      height: 340px !important;
      margin: auto;
      left: 0;
      right: 0;
    }

    .pie {

      &-text {
        top: 24px;
        left: 0;


        h3 {
          font-size: 36px;
          line-height: 67px;
        }

        p {
          font-size: 13px;
          line-height: 19px;
        }
      }

      svg {
        position: absolute;
        transform: rotate(225deg);
        width: 130px;
        height: 130px;
        top: 100px;
        right: 0;
        left: 185px;
      }
    }
  }

  .quick-test {
    padding-bottom: 70px;
    padding-top: 70px;

    .container__info--bg span:first-child {
      right: 47px;
    }

    .container__info--bg span:last-child {
      right: 180px;
    }

    ul {
      margin: auto;
    }

    h1 {
      font-size: 36px;
      line-height: 42px;
      text-align: center;
    }

    h3 {
      text-align: center;
      max-width: 100%;
      padding-left: 21px;
      padding-right: 21px;
    }

    p.container__info {
      font-size: 20px;
      text-align: center;
    }

    li {
      font-size: 16px;
    }

    .container__info-sub {
      text-align: center;
      margin: auto;
    }

    button {
      display: block;
      margin: auto;
    }

    .custom-button--green {
      display: block;
      margin: auto;
    }

  }

  .objects {
    padding-bottom: 70px;
    padding-top: 70px;

    h1 {
      font-size: 36px;
      line-height: 42px;
      text-align: center;
    }

    p {
      font-size: 20px;
      text-align: center;
    }

    &__item {

      h3 {
        font-size: 24px;
        line-height: 31px;
      }

      p.objects__info {
        font-size: 16px;
      }

      p.objects__price {
        font-size: 32px;
      }
    }
  }

  .reviews {
    padding-bottom: 70px;
    padding-top: 70px;

    &__header {
      flex-direction: column;
      justify-content: center;

      h1 {
        font-size: 36px;
        line-height: 42px;
      }

      a {
        margin-left: 50px;
        margin-top: 30px;
      }
    }

    .columns {
      display: flex;
    }

    &__user {
      justify-content: left;
      width: 350px;

      h3 {
        font-size: 24px;
      }

      img {
        width: 85px;
        height: 85px;
      }
    }

    &__comment {
      font-size: 16px;
      text-align: center;
    }

    &__arrow {
      width: 18px;
      height: 18px;

      &--right {
        right: -16px;
      }
    }
  }

  .agents {
    padding-top: 70px;
    padding-bottom: 0;

    ::-webkit-scrollbar {
      display: none;
    }

    h1 {
      font-size: 36px;
      line-height: 42px;
    }

    p {
      font-size: 20px;

      &.agents__position {
        height: auto;
      }
    }

    &__number {
      font-size: 24px;

      &:before {
        display: block;
      }
    }

    .columns {
      max-height: 540px;
      overflow-x: unset;
      overflow-y: scroll;
      scroll-snap-type: y mandatory;
    }

  }

  .contacts {

    h1 {
      font-size: 36px;
      line-height: 42px;
      text-align: center;
    }

    p {
      font-size: 20px;
      text-align: center;
    }

    &__information {

      ul {

        li {
          padding-left: 85px;

          p {
            text-align: left;
          }
        }
      }
    }

    &__description {
      margin-top: 70px;
      margin-bottom: 40px;
      font-size: 20px;
    }
  }

  .custom-input {


    &__textarea {
      max-height: 190px;
    }
  }

  .contact-form__textarea {
    max-height: 190px;
  }

  .footer {
    height: 340px;

    .container {
      height: 100%;
      flex-direction: column;
    }

    &__links {

      margin-right: 0;
      margin-left: 0;
    }
  }
}

@media (max-width: 686px) {

  .pie-wrapper {

    &__text {
      left: 120px;
    }
  }
}

@media (max-width: 686px) {

  .pie-wrapper {

    &__text {
      left: 90px;
    }
  }

  .main__address-item:last-child {
    display: none;
  }
}

@media (max-width: 550px) {
  .main .cloud-block h1 {
    font-size: 50px;
    line-height: 62px;
  }

  .modal-content {

    &__test {
      padding: 20px 30px 25px;
    }

    &__header {
      padding: 15px 30px;
    }
  }

  .slider {

    &__item {
      max-width: 352px;
      max-height: 220px;
      min-height: 220px;
      padding: 30px 22px;
      scroll-snap-align: start;

      img {
        max-height: 250px;
      }

      &:nth-child(1), &:nth-child(2), &:nth-child(8) {
        img {
          max-height: 244px;
        }
      }
      &:nth-child(4), &:nth-child(5) {
        img {
          max-height: 254px;
        }
      }
      &:nth-child(3) {

        .img-wrapper {
          left: 0;
        }

        img {
          max-height: 250px;
        }
      }
    }

    .agent {

      &__name {
        font-weight: 500;
        font-size: 18px;
        line-height: 23px;
      }
      &__profession {
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
      }
      &__links a {
        font-weight: 700;
        font-size: 14px;
        line-height: 17px;

        &:first-child {
          margin-bottom: 10px;
        }

        &:before {
          top: 0;
        }
      }
    }
  }
}

@media (max-width: 500px) {

  .contacts__information ul li {

    &:nth-child(3),
    &:nth-child(4) {
      padding-top: 0;

      p {
        font-size: 20px;
      }
    }
  }

  .pie-wrapper {
    &__text {
      font-size: 16px;
      top: 150px;
      left: 40px;
    }
  }
}

@media (max-width: 425px) {
  .reviews {
    &__user {
      width: 100%;
    }
  }

  .main {
    text-align: center;
    background: url("../img/mobile-main-hero-copy.png") no-repeat bottom;
  }

  .contacts {

    &__map {
      height: 353px;
    }
  }

  .modal-content {
    width: 92%;

    ul {
      margin-bottom: 34px;
    }

    &__buttons {
      flex-direction: column;

      .custom-button {
        width: 100%;

        &:first-child {
          margin-bottom: 10px;
        }
      }
    }

    &__label {
      padding-top: 3px;
    }
  }
}

@media (max-width: 405px) {
  .main__address {
    display: none;
  }

  .contacts__information ul li {
    padding-left: 60px;
  }

  .pie-wrapper {
    .pie, .pie-shadow {
      width: 300px !important;
      height: 300px !important;
      margin: auto;
      left: 0;
      right: 0;
    }

    .pie {

      &-text {
        top: 24px;
        left: 0;


        h3 {
          font-size: 36px;
          line-height: 67px;
        }

        p {
          font-size: 13px;
          line-height: 19px;
        }
      }

      svg {
        position: absolute;
        transform: rotate(225deg);
        width: 130px;
        height: 130px;
        top: 100px;
        right: 0;
        left: 185px;
      }
    }
  }

  .slider {

    &__item {
      max-width: 300px;
      max-height: 200px;
      min-height: 200px;
      padding: 15px 10px;
      scroll-snap-align: start;

      img {
        max-height: 250px;
      }

      &:nth-child(1), &:nth-child(2), &:nth-child(8) {
        img {
          max-height: 244px;
        }
      }
      &:nth-child(4), &:nth-child(5) {
        img {
          max-height: 254px;
        }
      }
      &:nth-child(3) {

        .img-wrapper {
          left: 0;
        }

        img {
          max-height: 250px;
        }
      }
    }

    .img-wrapper {
      left: 0;
    }

    .agent {

      &__name {
        font-weight: 500;
        font-size: 16px;
        line-height: 21px;
      }
      &__profession {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
      }
      &__links a {
        font-weight: 700;
        font-size: 12px;
        line-height: 16px;

        &:first-child {
          margin-bottom: 8px;
        }

        &:before {
          top: 0;
        }
      }
    }
  }
}

@media (max-width: 350px) {
  .slider__item:nth-child(3) .img-wrapper {
    left: -10px;
  }

}