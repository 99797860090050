.footer {
  padding-bottom: 40px;
  padding-top: 40px;
  background: #F2F2F2;

  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }

  &__logo {
    position: relative;
    display: flex;
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.07em;
    text-transform: uppercase;

    a {
      color: #C7C9C6;
    }
  }

  &__links {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-left: 100px;

    h1 {
      width: 100%;
      margin-bottom: 12px;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      text-align: center;
      color: $black-dark;
    }

    a {
      &:not(:last-child) {
        margin-right: 16px;
      }

      path {
        transition: fill ease-in-out .3s;
      }

      &:hover {

        path {
          fill: $pink;
        }
      }
    }
  }

  &__phone {
    display: flex;
    flex-direction: column;

    a {
      &:first-child {
        font-weight: 500;
        font-size: 24px;
        line-height: 29px;
        color: $black-dark;

        &:hover {
          color: $pink;
        }
      }

      &:last-child {
        position: relative;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: $green;
        width: fit-content;

        &:after {
          position: absolute;
          content: '';
          width: 0;
          height: 1px;
          display: block;
          right: 0;
          background: $green;
          transition: 300ms;
        }

        &:hover {
          &:after {
            width: 100%;
          }
        }
      }
    }
  }

  &__rights {
    width: 100%;
    padding: 16px 0;
    text-align: center;
    background: $green;

    font-weight: 500;
    font-size: 14px;
    line-height: 17px;

    color: $white;
  }
}